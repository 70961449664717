<template>
  <v-content>
    <v-progress-linear v-if="loading" :indeterminate="true" class="ma-0" color="accent"> </v-progress-linear>
    <v-alert
      v-if="ordersShipping.length > 0"
      v-ripple
      :value="true"
      type="error"
      :style="alertLocation.length > 0 ? 'top: 66px;' : ''"
      class="title alert-dealer-geo"
      @click="dialog.route = true"
    >
      <span class="font-weight-bold">{{ ordersShipping.length }}</span> pedido en ruta
    </v-alert>

    <v-container fluid class="pa-0" style="position: fixed;top: 0;z-index: 1;top: 64px;" fixed>
      <v-alert v-if="alertLocation" v-model="alertLocation" dismissible type="error" class="ma-0">
        Pedidos
        <template v-for="(order, index) in alertOrders">
          <router-link
            :key="index"
            class="white--text"
            target="_blank"
            :to="{ name: 'orderIndex', params: { id: order.id } }"
          >
            {{ order.id }}
            <span v-if="index + 1 !== alertOrders.length"> - </span>
          </router-link>
        </template>
        no han sido geolocalizados
      </v-alert>
      <v-alert
        v-if="alertLocationDuplicate && locationDuplicate.length"
        v-model="alertLocationDuplicate"
        dismissible
        type="error"
        class="ma-0"
      >
        Pedidos
        <router-link
          v-for="(o, index) in locationDuplicate"
          :key="index"
          class="white--text"
          target="_blank"
          :to="{ name: 'orderIndex', params: { id: o } }"
        >
          {{ o }}
          <span v-if="index + 1 !== locationDuplicate.length"> - </span>
        </router-link>
        la ubicación de estos pedidos es la misma.
      </v-alert>
    </v-container>

    <v-card
      v-if="(($mq === 'xs' || $mq === 'sm' || $mq === 'md') && bottomNav === 1) || $mq === 'lg' || $mq === 'xl'"
      :class="
        $mq === 'xs' || $mq === 'sm' || $mq === 'md' ? 'elevation-4 dialog-filtros full' : 'elevation-4 dialog-filtros'
      "
    >
      <TitleDialog :title="`Filtrar`">
        <template slot="icons">
          <v-btn icon flat class="my-0 mx-0" color="white" @click="getOrders">
            <v-icon> refresh </v-icon>
          </v-btn>
          <v-btn icon flat class="my-0" color="white" @click="showFilter = !showFilter">
            <v-icon v-if="showFilter"> keyboard_arrow_up </v-icon>
            <v-icon v-else> keyboard_arrow_down </v-icon>
          </v-btn>
          <template v-if="this.$mq === 'xs' || this.$mq === 'sm' || this.$mq === 'md'">
            <v-btn icon flat class="my-0" color="white" @click="bottomNav = 0">
              <v-icon> cancel </v-icon>
            </v-btn>
          </template>
        </template>
      </TitleDialog>
      <v-layout v-if="showFilter" row wrap>
        <v-flex xs12>
          <v-divider></v-divider>
          <v-subheader>
            Horarios de entrega y repartidores
          </v-subheader>
          <v-divider></v-divider>
        </v-flex>
        <v-flex xs12 class="pa-4">
          <v-alert :value="true" type="info"> {{ orders.length }} Pedidos filtrados </v-alert>
          <v-subheader class="pa-0 ma-0">Horarios de entrega</v-subheader>
          <template v-if="schedulesList.length > 0">
            <v-checkbox
              v-for="schedule in schedulesList"
              :key="schedule.horario"
              v-model="scheduleSelected"
              :label="schedule.horario"
              :value="schedule.horario"
              hide-details
            ></v-checkbox>
          </template>
          <v-combobox
            v-else
            v-model="filters.schedule"
            :items="schedules"
            label="Horarios"
            :item-text="e => $options.filters.toHTML(e.horario)"
            chips
            clearable
            multiple
            class="hidden"
          >
            <template slot="selection" slot-scope="data">
              <v-chip :selected="data.selected" close small @input="removeSchedule(data.item)">
                <strong>{{ data.item.horario }}</strong>
              </v-chip>
            </template>
          </v-combobox>
          <template v-if="_.size(itemsFilterDealer) > 0">
            <v-combobox
              v-model="filters.dealer"
              :items="itemsFilterDealer"
              label="Repartidores"
              item-text="name"
              chips
              clearable
              multiple
              hide-details
            >
              <template slot="selection" slot-scope="data">
                <v-chip :selected="data.selected" close small @input="removeDealer(data.item)">
                  <strong>{{ data.item.name }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </template>
          <v-switch v-model="show.dealers" color="secondary" :label="'Repartidores'" hide-details> </v-switch>
          <v-switch v-model="onlyReparto" color="secondary" :label="'Solo Reparto'" hide-details> </v-switch>
          <v-btn v-if="ordersAll.length > 0" color="primary" class="mx-0 mt-4 mb-0" block @click="orders = ordersAll">
            Ver todos
          </v-btn>
        </v-flex>
        <v-flex v-if="routesList.length > 0" class="px-4 pb-2">
          <v-btn flat color="secondary" block @click="dialog.routeActives = !dialog.routeActives">
            {{ routesList.length }} rutas de reparto
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <v-card
      v-if="dialog.dealer"
      :class="$mq === 'xs' || $mq === 'sm' || $mq === 'md' ? 'elevation-2 cardLeft full' : 'elevation-2 cardLeft'"
      color="secondary"
      dark
    >
      <v-card-title style="position:absolute;width:100%;padding:10px 10px;">
        <v-spacer></v-spacer>
        <v-btn icon flat class="my-0 mr-0" @click="dialog.dealer = false">
          <v-icon> close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-xs-center">
        <v-avatar :tile="false" :size="128" color="grey lighten-4 mb-2">
          <img :src="dealer.photo_large" alt="avatar" />
        </v-avatar>
        <h4 class="display-1 font-weight-light" v-text="dealer.name"></h4>
        <p>
          <v-rating v-model="dealer.stars" background-color="white lighten-3" color="white" readonly small></v-rating>
          Calificación: {{ dealer.stars }} - Total Entregas {{ dealer.total }}
        </p>
        <p>Ultima ubicacion fue {{ $moment(dealer.position.fecha).format('DD-MM-YYYY HH:mm:ss') }}</p>
      </v-card-text>
      <v-list v-if="dealer.orders.length > 0" class="secondary white--text pa-0" light dark three-line>
        <template v-for="(order, index) in dealer.orders">
          <v-list-tile :key="index" avatar ripple @click="routeOrder(order.id)">
            <v-list-tile-content>
              <v-list-tile-title> {{ order.position + 1 }} - {{ order.shipping.name }} </v-list-tile-title>
              <v-list-tile-sub-title class="caption">
                {{ order.shipping.address }}
                {{ order.shipping.address_number }}, {{ order.shipping.more }} - {{ order.shipping.state }} <br />
                {{ order.schedule.horario }}
              </v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <span color="white--text">{{ order.id }}</span>
              <v-icon>
                reply
              </v-icon>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider :key="`divider-${index}`"></v-divider>
        </template>
      </v-list>
      <v-divider v-if="dealer.orders.length === 0"></v-divider>
      <v-card-text v-if="dealer.orders.length === 0 && general.status === 3" class="text-xs-center">
        <h4 class="headline font-weight-light text-center">
          No tiene ruta de reparto asignada
        </h4>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>

    <v-card
      v-if="showOrder"
      :class="$mq === 'xs' || $mq === 'sm' || $mq === 'md' ? 'elevation-2 cardLeft full' : 'elevation-2 cardLeft'"
    >
      <TitleDialog :title="`Pedido ${order.id}`" :subtitle="` (${order.status.name})`" close :close-action="closeOrder">
        <template slot="icons">
          <v-btn :to="{ name: 'orderIndex', params: { id: order.id } }" class="my-0" color="white" icon flat>
            <v-icon> forward </v-icon>
          </v-btn>
        </template>
      </TitleDialog>
      <v-card-text>
        <p class="subheading mb-0 font-weight-medium " v-html="order.shipping.name"></p>
        <p class="caption mb-0">
          {{ order.shipping.address }} {{ order.shipping.address_number }}, {{ order.shipping.more }},
          {{ order.shipping.state }}
        </p>
        <p v-if="order.priority.name === 'alta'" class="body-1 mb-0 font-weight-bold secondary--text">
          {{ order.priority.important }}
        </p>
        <v-chip class="mx-0 px-2" color="info" text-color="white" small label v-html="order.schedule.horario"></v-chip>
        <v-divider v-if="order.position > 0 && order.status.id === 2" class="my-2"></v-divider>
        <template v-if="order.position > 0 && order.status.id === 2">
          <v-chip color="error" class="mb-0" text-color="white" large> {{ order.position }} en la ruta </v-chip>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-subheader class="font-weight-medium"> Productos </v-subheader>
      <v-list class="ma-0" three-line dense>
        <template v-for="(product, index) in order.products">
          <v-list-tile :key="index" avatar>
            <v-list-tile-avatar tile :size="50">
              <img :src="product.photo_small" style="width: 45px;height: 55px;" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ product.quantity }} x {{ product.name }}</v-list-tile-title>
              <template v-if="_.size(product.confeccion) > 0">
                <v-list-tile-sub-title>
                  Diseñadora: <strong>{{ product.confeccion.name }}</strong>
                </v-list-tile-sub-title>
                <v-chip
                  v-if="product.confeccion.status === 0"
                  class="ma-0 mt-1"
                  color="warning"
                  text-color="black"
                  small
                >
                  <TimeGo :number="product.confeccion.seconds"></TimeGo>
                </v-chip>
              </template>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
      <v-divider v-if="order.status.id === 3 || order.status.id === 4"></v-divider>
      <v-subheader v-if="order.status.id === 3 || order.status.id === 4">
        Repartidor
      </v-subheader>
      <v-card-text v-if="order.status.id === 3 || order.status.id === 4" class="pt-0">
        <v-layout align-center justify-center class="pa-0">
          <v-flex xs2>
            <v-avatar :size="50" color="grey lighten-4">
              <img :src="dealer.photo_large" alt="avatar" />
            </v-avatar>
          </v-flex>
          <v-flex xs8>
            <div>
              <div class="subheading mb-0 font-weight-medium">
                {{ dealer.name }}
              </div>
              <div v-if="order.status.id === 3" class="caption">
                Pedido va en camino
                {{ $moment.unix(order.dealer.info.date).fromNow() }}
              </div>
            </div>
          </v-flex>
          <v-flex xs2 class="text-xs-right">
            <div>
              <v-avatar color="secondary" :size="40">
                <span class="white--text subheading">{{ order.position }}</span>
              </v-avatar>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <template v-if="order.status.id === 4">
        <v-layout>
          <v-flex xs4>
            <v-img :src="order.photo" :srcset="order.photo_large"></v-img>
          </v-flex>
          <v-flex xs8 pa-3 class="caption" v-html="order.comentario"></v-flex>
        </v-layout>
        <v-divider></v-divider>
      </template>
      <v-card-actions v-if="order.status.id === 2 || order.status.id === 3">
        <v-btn
          v-if="order.status.id === 2 && !order.design.active && order.priority.name == 'baja'"
          block
          color="warning"
          :loading="loadingBtn"
          :disabled="loadingBtn"
          class="mb-1"
          @click="changeOrderPriority(1)"
        >
          <v-icon left dark> arrow_upward </v-icon> Subir Prioridad
        </v-btn>
        <v-btn
          v-if="order.status.id === 2 && !order.design.active && order.priority.name == 'media'"
          block
          color="error"
          :loading="loadingBtn"
          :disabled="loadingBtn"
          class="mb-1"
          @click="changeOrderPriority(0)"
        >
          <v-icon left dark> arrow_downward </v-icon> Bajar Prioridad
        </v-btn>
        <v-btn
          v-if="order.status.id === 3"
          block
          color="error"
          :loading="loadingBtn"
          :disabled="loadingBtn"
          class="mb-1"
          @click="removeOrderRouteAction"
        >
          Quitar de la ruta
        </v-btn>
        <v-btn
          v-if="!checkOrderShipping && order.status.id === 2"
          block
          color="accent"
          :loading="loadingBtn"
          :disabled="loadingBtn"
          class="mb-1"
          @click="addRoute"
        >
          Asignar
        </v-btn>
        <v-btn
          v-if="checkOrderShipping && $mq !== 'sm'"
          block
          color="success"
          class="mb-1"
          @click="dialog.route = true"
        >
          Ver ruta
        </v-btn>
        <v-btn
          v-if="checkOrderShipping"
          block
          color="error"
          :loading="loadingBtn"
          :disabled="loadingBtn"
          class="mb-1"
          @click="removeRoute"
        >
          Quitar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialog.route"
      :fullscreen="$mq === 'xs' || $mq === 'sm' || $mq === 'md'"
      persistent
      max-width="800px"
    >
      <v-card>
        <TitleDialog
          title="Ruta de reparto"
          close
          :close-action="
            () => {
              dialog.route = false
            }
          "
        >
          <template slot="icons">
            <v-btn icon flat @click="handleClickDirectionsView">
              <v-icon> directions </v-icon>
            </v-btn>
            <v-btn icon flat @click="showRoute = !showRoute">
              <v-icon v-if="!showRoute"> edit_location </v-icon>
              <v-icon v-if="showRoute"> location_off </v-icon>
            </v-btn>
          </template>
        </TitleDialog>

        <v-data-table
          v-if="showRoute"
          :headers="[
            { text: 'Nº', align: 'right', sortable: false },
            { text: 'Pedido', align: 'right', sortable: false },
            { text: 'Direccion', align: 'left', sortable: false },
            { text: '', sortable: false }
          ]"
          :items="ordersShipping"
          hide-actions
          class="elevation-0"
          disable-initial-sort
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-right">{{ props.item.position }}</td>
            <td class="text-xs-right">{{ props.item.id }}</td>
            <td class="text-xs-left">
              <span v-html="props.item.shipping.address"></span>
              {{ props.item.shipping.address_number }}, <span v-html="props.item.shipping.state"></span> <br />
              <span v-html="props.item.schedule.horario"></span>
            </td>
            <td class="text-xs-right">
              <v-btn
                flat
                icon
                :disabled="props.item.position === 1"
                @click="changePositionRoute(props.item, 1, props.index)"
              >
                <v-icon>arrow_drop_up</v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                :disabled="props.item.position === ordersShipping.length"
                @click="changePositionRoute(props.item, 0, props.index)"
              >
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
              <v-btn icon color="error" @click="removeRouteDialog(props.item)">
                <v-icon>delete_outline</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-container grid-list-lg>
          <v-layout v-if="ordersShipping.length > 0" row wrap>
            <v-flex xs4>
              <v-select
                v-model="route.dealer"
                :items="dealersData"
                menu-props="auto"
                item-text="name"
                :item-value="item => item"
                label="Repartidor"
                hide-details
                prepend-icon="person"
                @change="changeDealer"
              ></v-select>
            </v-flex>
            <v-flex xs4>
              <v-select
                v-model="route.car"
                :items="cars"
                menu-props="auto"
                :item-value="item => item"
                :item-text="it => `${it.patente} - ${it.marca} ${it.modelo}`"
                label="Vehiculo"
                hide-details
                prepend-icon="local_shipping"
              ></v-select>
            </v-flex>
            <v-flex xs4>
              <v-select
                v-model="route.state"
                item-text="name"
                :item-value="item => item"
                :items="listStates"
                label="Estado"
                prepend-icon="list_alt"
              ></v-select>
            </v-flex>
            <v-flex xs4 class="py-0 my-0">
              <v-switch
                v-model="route.email"
                class="ma-0 py-0 pb-0"
                label="Notificar por email"
                :value="true"
                small
              ></v-switch>
            </v-flex>
            <v-flex xs4 class="py-0 my-0">
              <v-switch
                v-model="route.whatsapp"
                class="ma-0 py-0 pb-0"
                label="Notificar por sms/whatsapp"
                :value="true"
                small
              ></v-switch>
            </v-flex>
            <v-flex v-if="routeAlert" xs12 class="py-0 my-0">
              <v-alert :value="true" type="info">
                <span class="font-weight-bold">{{ route.dealer.name }}</span> tiene una ruta pendiente. Los pedidos se
                agregaran a su ruta pendiente
              </v-alert>
            </v-flex>
          </v-layout>
          <v-layout v-else row wrap>
            <v-flex> No hay pedidos asignados</v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="default" flat :disabled="loadingRoute" @click.native="dialog.route = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            flat
            :loading="loadingRoute"
            :disabled="loadingRoute || ordersShipping.length === 0"
            @click.native="createRoute"
          >
            Enviar correo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.routeActives"
      :fullscreen="$mq === 'xs' || $mq === 'sm' || $mq === 'md'"
      persistent
      max-width="800px"
    >
      <v-card>
        <TitleDialog
          title="Rutas de reparto activas"
          close
          :close-action="
            () => {
              dialog.routeActives = false
            }
          "
        >
          <template slot="icons">
            <v-btn
              :title="!routeShowAll ? 'Mostrar todos' : 'Solo pendientes'"
              icon
              flat
              @click="routeShowAll = !routeShowAll"
            >
              <v-icon v-if="!routeShowAll"> work </v-icon>
              <v-icon v-if="routeShowAll"> work_off </v-icon>
            </v-btn>
          </template>
        </TitleDialog>
        <v-data-table
          :headers="[
            {
              text: 'Estado',
              align: 'left'
            },
            { text: 'Repartidor' },
            { text: 'Vehiculo' },
            { text: 'Pedidos' },
            { text: '' }
          ]"
          :items="routesList"
          :expand="false"
          hide-actions
          class="elevation-0"
          disable-initial-sort
        >
          <template v-slot:items="props">
            <tr @click="props.expanded = !props.expanded">
              <td>
                <v-chip v-if="props.item.status === 0" color="warning" text-color="white">
                  Aun no inicia
                </v-chip>
                <v-chip v-if="props.item.status === 1" color="error" text-color="white">
                  Ruta iniciada
                </v-chip>
                <v-chip v-if="props.item.status === 2" color="info" text-color="white">
                  En camino a finalizar
                </v-chip>
                <v-chip v-if="props.item.status === 3" color="success" text-color="white">
                  Finalizada
                </v-chip>
              </td>
              <td>{{ props.item.dealerInfo.name }}</td>
              <td>{{ getCar(props.item.car) }}</td>
              <td>{{ props.item.orders.length }}</td>
              <td>
                <v-btn v-if="general.status === 3" icon flat @click="handleClickDirectionsRoute(props.item.dealer)">
                  <v-icon> directions </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:expand="props">
            <v-layout align-center justify-center row fill-height class="py-2">
              <v-flex xs12 sm4>
                <v-select
                  v-model="routeStatus"
                  :items="[
                    {
                      id: 0,
                      name: 'Aun no inicia'
                    },
                    {
                      id: 1,
                      name: 'Ruta iniciada'
                    },
                    {
                      id: 2,
                      name: 'En camino a finalizar'
                    },
                    {
                      id: 3,
                      name: 'Finalizada'
                    }
                  ]"
                  menu-props="auto"
                  item-text="name"
                  item-value="id"
                  label="Cambiar estado"
                  hide-details
                />
              </v-flex>
              <v-flex xs12 sm2>
                <v-btn class="ma-0 mt-2 ml-3" color="accent" block @click="updateRouteStatus(props.item.id)">
                  <span>Actualizar</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-divider class="mt-3" />
            <v-data-table
              :headers="[
                { text: 'Orden', align: 'left', value: 'position', sortable: true },
                { text: 'Pedido', align: 'left', sortable: false },
                { text: 'Direccion', align: 'left', sortable: false },
                { text: 'Horario', align: 'left', sortable: false },
                { text: 'Estado', align: 'left', sortable: false }
              ]"
              :items="props.item.orders"
              hide-actions
              class="elevation-0 mx-0 my-0"
              disable-initial-sort
            >
              <template slot="items" slot-scope="pr">
                <td width="150" class="text-xs-left">
                  {{ pr.item.position }}
                  <v-btn
                    :disabled="pr.index === 0"
                    class="ma-0"
                    flat
                    icon
                    @click="updateRouteOrderPosition(props.item.id, pr.item.id, 'up')"
                  >
                    <v-icon>keyboard_arrow_up</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="pr.index + 1 === props.item.orders.length"
                    class="ma-0"
                    flat
                    icon
                    @click="updateRouteOrderPosition(props.item.id, pr.item.id, 'down')"
                  >
                    <v-icon>keyboard_arrow_down</v-icon>
                  </v-btn>
                </td>
                <td class="text-xs-left">{{ pr.item.id }}</td>
                <td class="text-xs-left">{{ pr.item.address }}</td>
                <td class="text-xs-left">{{ pr.item.schedule }}</td>
                <td class="text-xs-left">
                  <v-chip v-if="pr.item.status === 0" style="margin:0 0;" color="info" text-color="white">
                    Pendiente
                  </v-chip>
                  <v-chip v-if="pr.item.status === 1" style="margin:0 0;" color="success" text-color="white">
                    Entregado
                  </v-chip>
                  <v-chip v-if="pr.item.status === 2" style="margin:0 0;" color="error" text-color="white">
                    Problemas
                  </v-chip>
                </td>
              </template>
            </v-data-table>
            <v-divider />
          </template>
        </v-data-table>
        <v-divider />
      </v-card>
    </v-dialog>

    <div :style="$mq === 'xs' || $mq === 'sm' || $mq === 'md' ? 'padding-bottom: 57px;height: 100%;' : 'height: 100%;'">
      <gmap-map
        id="map"
        ref="myMap"
        :center="center"
        :zoom="zoom"
        :options="dark ? optionsDark : options"
        :style="`width:100%;height:100%;`"
      >
        <DirectionsRenderer
          travel-mode="DRIVING"
          :origin="origin"
          :waypoints="waypoints"
          :destination="destination"
          :mounted="directionsView"
        />
        <template v-for="(d, index) in dealersData">
          <GmapMarker
            v-if="d.position && show.dealers"
            :key="`D${index}`"
            :position="{ lat: d.position.lat, lng: d.position.lng }"
            :clickable="true"
            :draggable="false"
            :options="optionsMarked(d)"
            :title="d.name"
            @click="openDealer(d.id)"
          />
        </template>

        <template v-if="gpsMarkers.length > 0">
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
          </gmap-info-window>
        </template>

        <template v-for="(marker, index) in gpsMarkers">
          <GmapMarker
            :key="`GPS-${index}`"
            :position="{ lat: marker.lat, lng: marker.lng }"
            :clickable="true"
            :draggable="false"
            @click="toggleInfoWindow(marker, index)"
          />
        </template>
        <GmapMarker
          v-for="(order, key) in orders"
          :key="`P${key}`"
          :position="{
            lat: order.deliveryOptions.lat,
            lng: order.deliveryOptions.lng
          }"
          :clickable="false"
          :draggable="false"
          :options="optionsMarkerOrder(order)"
          :title="`Pedido ${order.id}`"
          @click="openDetail(order)"
        />
      </gmap-map>
    </div>

    <v-bottom-nav v-if="$mq === 'xs' || $mq === 'sm' || $mq === 'md'" :active.sync="bottomNav" :value="true" fixed>
      <v-btn color="accent" flat :value="0">
        <span>Mapa</span>
        <v-icon>location_on</v-icon>
      </v-btn>
      <v-btn color="accent" flat :value="1">
        <span>Filtros</span>
        <v-icon>filter_list</v-icon>
      </v-btn>
      <v-btn color="accent" flat :value="2">
        <span>Ruta</span>
        <v-icon>directions</v-icon>
      </v-btn>
    </v-bottom-nav>
  </v-content>
</template>

<script>
import qs from 'qs'
import DirectionsRenderer from '@/components/useful/DirectionsRenderer'
import { GET_API, ORDERS_MAP, ORDER, DEALER_ROUTE } from '../config'
import TitleDialog from './useful/titleDialog.vue'
import TimeGo from './useful/timeGo.vue'

export default {
  name: 'OrdersMap',
  components: { TitleDialog, TimeGo, DirectionsRenderer },
  metaInfo: {
    title: 'Mapa de pedidos'
  },
  props: ['day', 'states', 'dealerID'],
  data() {
    return {
      bottomNav: 0,
      zoom: 12,
      fab: false,
      alertLocation: false,
      alertLocationDuplicate: false,
      locationDuplicate: [],
      menuDay: false,
      showOrder: false,
      loading: false,
      loadingBtn: false,
      showRoute: true,
      showInput: false,
      loadingRoute: false,
      showFilter: true,
      onlyReparto: false,
      directionsView: false,
      route: {
        whatsapp: true,
        email: true,
        dealer: null,
        car: null,
        state: { id: 3, name: 'Despacho' }
      },
      show: {
        dealers: false,
        route: false,
        states: false
      },
      dialog: {
        dealer: false,
        route: false,
        routeActives: false
      },
      center: {
        lat: -33.4262,
        lng: -70.6429
      },
      filters: {
        day: this.$moment().format('YYYY-MM-DD'),
        state: { id: 2, name: 'Proceso' },
        dealer: [],
        schedule: []
      },
      options: {
        styles: [],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      },
      optionsDark: {
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#212121'
              }
            ]
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#212121'
              }
            ]
          },
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e'
              }
            ]
          },
          {
            featureType: 'administrative.land_parcel',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#181818'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#1b1b1b'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#2c2c2c'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#8a8a8a'
              }
            ]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                color: '#373737'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#3c3c3c'
              }
            ]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [
              {
                color: '#4e4e4e'
              }
            ]
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161'
              }
            ]
          },
          {
            featureType: 'transit',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#3d3d3d'
              }
            ]
          }
        ],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      },
      order: {},
      dealer: {},
      alertOrders: [],
      ordersAll: [],
      orders: [],
      ordersShipping: [],
      schedules: [],
      dealers: [],
      dealersData: [],
      itemsFilterDealer: [],
      listStates: [{ id: 2, name: 'Proceso' }, { id: 3, name: 'Despacho' }, { id: 4, name: 'Entregado' }],
      refApp: {},
      dialogTime1: false,
      timeStart: null,
      dialogTime2: false,
      timeEnd: null,
      searchGPS: false,
      gps: {
        timeEnd: null,
        timeStart: null,
        date: null
      },
      gpsMarkers: [],
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      routeActive: null,
      routesList: [],
      routesListAll: [],
      routeStatus: null,
      routeAlert: false,
      routeShowAll: false,
      schedulesList: [],
      scheduleSelected: []
    }
  },
  computed: {
    waypoints() {
      const r = []
      const { ordersShipping } = this
      ordersShipping.forEach((e, i) => {
        r.push({ location: e.deliveryOptions })
      })
      r.splice(-1, 1)
      return r
    },
    destination() {
      const { ordersShipping } = this
      if (ordersShipping.length > 0) {
        const lat = ordersShipping[ordersShipping.length - 1].deliveryOptions.lat
        const lng = ordersShipping[ordersShipping.length - 1].deliveryOptions.lng
        return { lat, lng }
      }
      return { query: 'Santiago, Chile' }
    },
    origin() {
      return { query: 'Cautin 879, Santiago, Chile' }
    },
    user() {
      return this.$store.getters.getUser
    },
    general() {
      return this.$store.getters.getGeneral
    },
    dark() {
      return this.$store.getters.getDark
    },
    checkOrderShipping() {
      const order = this._.find(this.ordersShipping, ['id', parseInt(this.order.id, 10)])
      if (order !== undefined) {
        return true
      }
      return false
    },
    massive() {
      return this.$store.getters.getMassive
    },
    pressKey() {
      const key = this.$store.getters.getKey
      return key
    },
    cars() {
      return this.$store.getters.getCars
    }
  },
  watch: {
    routeShowAll(e) {
      this.routesList = this._.filter(this.routesListAll, o => o.status !== 3)
      if (e) {
        this.routesList = this.routesListAll
      }
    },
    'general.date': function newDate(e) {
      this.getOrders()
      this.gps.date = e
    },
    'general.status': function newStatus() {
      this.getOrders()
    },
    scheduleSelected(e) {
      this.getOrders()
    },
    order(e, a) {
      if (a !== undefined && a.shippingDealer === undefined) {
        if (a.icons !== undefined) {
          const index = this._.findIndex(this.orders, ['id', a.id])
          this.restartIconOrder(this.orders[index], a.icons.original)
        }
      }
    },
    bottomNav(e) {
      if (e === 2) {
        this.dialog.route = true
      }
    },
    pressKey(e) {
      if (e.name === 'Escape') {
        this.dialog.dealer = false
        this.showOrder = false
        this.dialog.route = false
        this.restartIconOrder(this.order, this.order.icons.original)
      }
      if (e.name === 'Enter' && this.showOrder === true) {
        this.addRoute()
      }
      if (e.name === 'Space' && this.ordersShipping.length > 0) {
        this.dialog.route = true
      }
    },
    massive(e) {
      if (e.send === 1) {
        setTimeout(() => {
          if (e.key !== undefined) {
            this.sendRouteDealerMap(e.key)
          } else {
            this.loading = false
            this.btnSend = false
            this.dialog.finish = true
            this.ordersShipping = []
          }
        }, 2000)
      }
    },
    'route.dealer': function(e) {
      if (e !== null) {
        const cardID = e.car_id
        const car = this._.find(this.cars, ['id', parseInt(cardID, 10)])
        this.route.car = car
      }
    },
    'filters.dealer': function(d) {
      this.filterOrders()
    },
    'filters.schedule': function(e) {
      this.filterOrders()
    },
    ordersAll(order) {
      this.getSchedules(order)
    }
  },
  created() {
    this.$store.dispatch('updateTitle', 'Mapa de reparto')
    this.getOrders()
  },
  mounted() {
    this.center = {
      lat: this.user.store.latitude,
      lng: this.user.store.longitude
    }
    this.$refs.myMap.$mapPromise.then(map => {
      // eslint-disable-next-line no-undef
      const trafficLayer = new google.maps.TrafficLayer()
      trafficLayer.setMap(map)
    })
    this.gps.date = this.general.date
  },
  beforeDestroy() {
    // this.refApp.off()
  },
  methods: {
    async sendRouteDealerMap(key) {
      await this.$http.post(`${ORDERS_MAP}/${this.ordersShipping[key].id}/route`, {
        routeID: this.routeActive,
        route: this.route,
        position: this.ordersShipping[key].position
      })
      this.ordersShipping[parseInt(key, 10)].send = 0
      this.sendFirst()
    },
    async getOrders() {
      try {
        this.loading = true
        const res = await this.$http.get(ORDERS_MAP, {
          params: {
            day: this.general.date,
            state: { id: this.general.status, name: '' },
            horario: this.scheduleSelected,
            dealer: [],
            schedule: []
          }
        })
        const ids = []
        this.loading = false
        this.schedulesList = res.data.schedules
        this.orders = res.data.orders
        this.dealersData = res.data.dealers
        this.ordersAll = res.data.orders
        this.routesListAll = res.data.routes
        this.routesList = this._.filter(res.data.routes, o => o.status !== 3)
        res.data.dealers.forEach(e => {
          ids.push(e.id)
        })
        this.filterDealer()
        this.getAlertLocation(this.orders)
        this.checkDuplicateLat(this.orders)
        this.checkDealer(this.dealerID)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async removeOrderRoute() {
      try {
        await this.$http.delete(`${ORDERS_MAP}/${this.order.id}/route`)
        this.$store.dispatch('setConfirm', {
          active: false,
          title: '',
          description: '',
          action: {}
        })
        this.getOrders()
        this.showOrder = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
        this.showOrder = false
      }
    },
    async changeOrderPriority(status) {
      try {
        const prioridad = status ? 'media' : 'baja'
        this.order.priority.name = prioridad
        await this.$http.post(`${ORDER}/${this.order.id}/priority`, {
          status
        })
        this.getOrders()
      } catch (e) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async createRoute() {
      let error = false
      let msj = ''
      this.loadingRoute = true
      if (!this.routeAlert) {
        if (this.route.car == null) {
          error = true
          msj = 'Debe ingresar un vehiculo'
        } else if (this.route.dealer == null) {
          error = true
          msj = 'Debe seleccionar un repartidor'
        }
        if (!error) {
          try {
            const res = await this.$http.post(`${DEALER_ROUTE}`, {
              route: this.route
            })
            this.sendMails(res.data)
          } catch (error) {
            this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error intente nuevamente' })
            this.loadingRoute = false
          }
        } else {
          this.$store.dispatch('changeSnack', { active: true, text: msj })
          this.loadingRoute = false
        }
      }
      if (this.routeAlert) {
        this.sendMails(this.routeActive)
      }
    },
    async updateRouteStatus(route) {
      let error = false
      let msj = ''
      this.loadingRoute = true
      if (this.routeStatus == null) {
        error = true
        msj = 'Debe seleccionar un estado de ruta'
      }
      if (!error) {
        try {
          await this.$http.put(`${DEALER_ROUTE}/${route}/status`, {
            status: this.routeStatus
          })
          this.getOrders()
          this.loadingRoute = false
        } catch (error) {
          this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error intente nuevamente' })
          this.loadingRoute = false
        }
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: msj })
        this.loadingRoute = false
      }
    },
    async updateRouteOrderPosition(route, id, position) {
      let error = false
      let msj = ''
      this.loadingRoute = true
      if (!error) {
        try {
          await this.$http.put(`${DEALER_ROUTE}/${route}/order/${id}/position`, {
            position
          })
          this.getOrders()
          this.loadingRoute = false
        } catch (error) {
          this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error intente nuevamente' })
          this.loadingRoute = false
        }
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: msj })
        this.loadingRoute = false
      }
    },
    handleClickDirectionsRoute(dealer) {
      try {
        const _dealer = this._.find(this.itemsFilterDealer, ['id', parseInt(dealer, 10)])
        const check = this._.find(this.filters.dealer, ['id', parseInt(dealer, 10)])
        if (!check && _dealer) {
          this.filters.dealer.push(_dealer)
          this.dialog.routeActives = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    changeDealer() {
      this.routeAlert = false
      this.routeActive = null
      const routeList = this._.filter(this.routesListAll, o => o.status !== 3)
      routeList.forEach(e => {
        if (this.route.dealer.id === e.dealer && e.status !== 3) {
          this.routeAlert = true
          this.routeActive = e.id
        }
      })
    },
    getCar(e) {
      if (this.cars) {
        const car = this._.filter(this.cars, o => parseInt(o.id, 10) === e)
        return `${car[0].patente} - ${car[0].marca} ${car[0].modelo} ${car[0].anio}`
      }
      return
    },
    getDealer(e) {
      return this._.filter(this.dealers, o => parseInt(o.id, 10) === e)
    },
    handleClickDirectionsView() {
      this.dialog.route = false
      setTimeout(() => {
        this.directionsView = !this.directionsView
      }, 1000)
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = {
        lat: marker.lat,
        lng: marker.lng
      }
      this.infoOptions.content = marker.fecha
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    searchGPSRed() {
      this.$http({
        method: 'post',
        url: GET_API,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          p: 'searchGPS',
          gps: this.gps,
          dealer: this.dealer
        })
      }).then(res => {
        if (res.data.json.logs.length > 0) {
          this.gpsMarkers = res.data.json.logs
        } else {
          this.gpsMarkers = []
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'No registra movimientos el dia seleccionado'
          })
        }
      })
    },
    closeOrder() {
      this.restartIconOrder(this.order, this.order.icons.original)
      this.showOrder = false
    },
    changePositionRoute(order, type, index) {
      if (type) {
        const newPosition = order.position - 1
        const key = this._.findIndex(this.ordersShipping, ['position', newPosition])
        this.ordersShipping[key].position = order.position
        this.ordersShipping[index].position = newPosition
        this.resetRoute()
      } else {
        const newPosition = order.position + 1
        const key = this._.findIndex(this.ordersShipping, ['position', newPosition])
        this.ordersShipping[key].position = order.position
        this.ordersShipping[index].position = newPosition
        this.resetRoute()
      }
    },
    resetRoute() {
      this.ordersShipping = this._.orderBy(this.ordersShipping, ['position'], ['asc'])
      let position = 0
      this._.forEach(this.ordersShipping, (item, index) => {
        position += 1
        const key = this._.findIndex(this.orders, ['id', item.id])
        this.orders[key].position = position
        this.orders[key].icons.default = `${process.env.BASE_URL}img/position/${this.orders[key].position}.png`
        this.ordersShipping[index] = this.orders[key]
      })
    },
    removeRouteDialog(order) {
      this.order = order
      this.removeRoute()
    },
    removeRoute() {
      this.loadingBtn = true
      setTimeout(() => {
        this.ordersShipping.splice(this.ordersShipping.indexOf(this.order), 1)
        this.ordersShipping = [...this.ordersShipping]
        this.order.icons.default = this.order.icons.original
        this.loadingBtn = false
        this.resetRoute()
      }, 1000)
    },
    addRoute() {
      this.loadingBtn = true
      setTimeout(() => {
        this.order.shippingDealer = true
        this.order.position = this.ordersShipping.length + 1
        this.order.icons.default = `/img/position/${this.order.position}.png`
        this.ordersShipping.push(this.order)
        this.loadingBtn = false
        this.showOrder = false
      }, 500)
    },
    optionsMarkerOrder(order) {
      let icon = order.icons.default
      if (this.onlyReparto) {
        icon = order.icons.dealer
      }

      const options = {
        icon
      }
      return options
    },
    optionsMarked(dealer) {
      const options = {
        icon: dealer.photo_small
      }
      return options
    },
    checkDealer(id) {
      if (id !== undefined) {
        const dealer = this._.find(this.dealersData, ['id', parseInt(id, 10)])
        this.filters.dealer.push(dealer)
      }
    },
    filterDealer() {
      const dealers = this._.groupBy(this.ordersAll, 'dealer.id')
      const dealersData = []
      this._.forEach(dealers, value => {
        if (value[0].dealer !== null) {
          dealersData.push(value[0].dealer)
        }
      })
      this.itemsFilterDealer = this._.size(dealers) > 0 ? dealersData : []
    },
    getAlertLocation(orders) {
      this.alertOrders = this._.filter(orders, o => o.location.active === 0)
      this.alertLocation = this.alertOrders.length > 0
    },
    sendRoute() {
      this.loadingRoute = true
    },
    sendMails(idRoute) {
      this.routeActive = idRoute
      this._.forEach(this.ordersShipping, (item, index) => {
        this.ordersShipping[index].send = 1
      })
      this.sendFirst()
    },
    sendFirst() {
      const key = this._.findKey(this.ordersShipping, { send: 1 })
      if (key !== undefined) {
        this.$store.dispatch('changeMassive', { key, send: 1 })
      } else {
        this.resetDialogRoute()
        this.loadingRoute = false
      }
    },
    resetDialogRoute() {
      this.dialog.route = false
      this.route = {
        whatsapp: true,
        email: true,
        dealer: null,
        car: null,
        state: { id: 3, name: 'Despacho' }
      }
      this.ordersShipping = []
      this.loadingRoute = false
      this.showOrder = false
      this.order = {}
      this.getOrders()
    },
    routeOrder(id) {
      this.$router.push({ name: 'orderIndex', params: { id } })
    },
    openDealer(key) {
      const dealer = this._.find(this.dealersData, ['id', parseInt(key, 10)])
      const dealers = this._.groupBy(this.ordersAll, 'dealer.id')
      this.dialog.dealer = true
      this.showOrder = false
      this.dealer = dealer
      this.dealer.orders = dealers[key] !== undefined ? dealers[key] : []
    },
    restartIconOrder(order, original) {
      if (order.shippingDealer === undefined) {
        order.icons.default = original
      }
    },
    openDetail(order) {
      this.dialog.dealer = false
      this.showOrder = true
      this.order = order

      if (this.order.shippingDealer === undefined && this.order.icons.default !== order.icons.selected) {
        this.order.icons.original = order.icons.default
        this.order.icons.default = order.icons.selected
      }

      if (this.order.status.id === 3 || this.order.dealer.id > 0) {
        const dealer = this._.find(this.dealersData, ['id', parseInt(this.order.dealer.id, 10)])
        this.dealer = dealer
      }

      this.$refs.myMap.$mapPromise.then(map => {
        const center = new google.maps.LatLng(this.order.deliveryOptions.lat, this.order.deliveryOptions.lng)
        map.panTo(center)
      })
    },
    getSchedules(orders) {
      const schedules = []
      const orders_ = this._.groupBy(orders, res => res.schedule.horario)
      this._.map(orders_, res => {
        schedules.push(res[0].schedule)
      })
      this.schedules = schedules
    },
    filterOrders() {
      const self = this
      const orders = this.ordersAll
      let filterOrder = []

      if (this.filters.schedule.length > 0 && this.filters.dealer.length === 0) {
        this.filters.schedule.forEach(el => {
          const filters = self._.filter(orders, ['schedule', el])
          filterOrder = this._.union(filterOrder, filters)
        })
      }

      if (this.filters.schedule.length === 0 && this.filters.dealer.length > 0) {
        this.filters.dealer.forEach(el => {
          const filters = self._.filter(orders, ['dealer.id', el.id])
          filterOrder = this._.union(filterOrder, filters)
        })
      }

      if (this.filters.schedule.length > 0 && this.filters.dealer.length > 0) {
        this.filters.dealer.forEach(dealer => {
          this.filters.schedule.forEach(schedule => {
            const filters = self._.filter(orders, {
              schedule,
              dealer: { id: dealer.id }
            })
            filterOrder = this._.union(filterOrder, filters)
          })
        })
      }

      if (this.filters.schedule.length === 0 && this.filters.dealer.length === 0) {
        filterOrder = orders
      }
      this.orders = filterOrder
    },
    removeDealer(item) {
      this.filters.dealer.splice(this.filters.dealer.indexOf(item), 1)
      this.filters.dealer = [...this.filters.dealer]
    },
    removeSchedule(item) {
      this.filters.schedule.splice(this.filters.schedule.indexOf(item), 1)
      this.filters.schedule = [...this.filters.schedule]
    },
    removeOrderRouteAction() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: 'Quitar de la ruta?',
        description: '',
        action: this.removeOrderRoute
      })
    },
    checkDuplicateLat() {
      const lodash = this._
      const a = this.orders
      const filter = lodash.filter(
        lodash.uniq(
          lodash.map(a, item => {
            const search = lodash.filter(a, {
              deliveryOptions: {
                lat: item.deliveryOptions.lat,
                lng: item.deliveryOptions.lng
              }
            })
            if (search.length > 1) {
              return item.id
            }
            return false
          })
        ),
        value => value
      )
      this.alertLocationDuplicate = filter.length > 0
      this.locationDuplicate = filter
    }
  }
}
</script>

<style>
.alert-dealer-geo {
  position: absolute !important;
  z-index: 4;
  right: 270px;
  top: 2px;
}
.dialog-filtros {
  position: fixed !important;
  z-index: 1;
  right: 5px;
  top: 69px;
  width: 260px;
}
.dialog-filtros.full {
  width: 100%;
  right: 0px;
  top: 0px;
  z-index: 9;
  height: 100%;
}
.centerAlert {
  width: 300px;
  top: 20px;
  z-index: 1;
  position: absolute;
  right: 20px;
}
.cardRight {
  width: 300px;
  top: 20px;
  z-index: 1;
  position: absolute;
  right: 20px;
}
.cardLeft {
  width: 400px;
  top: 5px;
  z-index: 1;
  position: absolute !important;
  left: 5px;
}
.cardLeft.full {
  width: 100%;
  top: 0px;
  z-index: 1;
  position: fixed !important;
  left: 0px;
  z-index: 9;
  height: 100%;
}
.gm-style img[src^="http://m.rosalinda.cl/imagen.php?repartidor_photo"] {
  border-radius: 100% !important;
  background: #ff5252;
  padding: 0px !important;
  border: 0px #ff5252 solid !important;
}
.gm-style img[src^="http://m.rosalinda.cl/imagen.php?repartidor_photo"] {
  box-sizing: border-box;
  height: 80px;
  width: 80px;
  border-radius: 10px;
}
</style>
