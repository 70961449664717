import { MapElementFactory } from 'vue2-google-maps'

export default MapElementFactory({
  name: 'directionsRenderer',

  ctr() {
    return window.google.maps.DirectionsRenderer
  },

  events: [],

  mappedProps: {},

  props: {
    origin: { type: Object },
    destination: { type: Object },
    travelMode: { type: String },
    waypoints: { type: Array },
    mounted: { type: Boolean, default: false }
  },

  afterCreate(directionsRenderer) {
    let directionsService = new window.google.maps.DirectionsService()

    this.$watch(
      () => [this.origin, this.destination, this.travelMode, this.waypoints, this.mounted],
      () => {
        let { origin, destination, travelMode, waypoints, mounted } = this
        if (!origin || !destination || !travelMode || !mounted) {
          if (origin && destination) {
            directionsRenderer.setDirections({ routes: [] })
          }
          return
        }

        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode
          },
          (response, status) => {
            if (status !== 'OK') return
            directionsRenderer.setDirections(response)
          }
        )
      }
    )
  }
})
